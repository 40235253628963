import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  PageHeader,
  Section,
  Content,
  Row,
  Col,
} from "../styles/StyledElements"
import { contentMapMarkdown } from "../components/pageUtilities"
const { slugFormat } = require("../utilities/slugFormat")

export default function Signals({ data, location }) {
  const markdownMap = contentMapMarkdown(data.markdown.nodes)
  const title = "Signals Index"

  return (
    <Layout location={location}>
      <Seo title={title} />
      <PageHeader>
        <Content>
          <Row>
            <Col>
              <h1>{title}</h1>
            </Col>
          </Row>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <Row>
            <Col>
              <div
                className={"description"}
                dangerouslySetInnerHTML={{
                  __html: markdownMap.get("description-signals").html,
                }}
              />
            </Col>
          </Row>
        </Content>
      </Section>
      <Section>
        <Content>
          <ul>
            {data.allAirtable.nodes.map(node => (
              <li key={node.recordId}>
                <Link to={`/signals/${slugFormat(node.data.Name)}`}>
                  {node.data.Name}
                </Link>
              </li>
            ))}
          </ul>
          {/* <CardsSet type={"trend"} nodes={data.forecasts.nodes} /> */}
        </Content>
      </Section>
    </Layout>
  )
}

// export default function Signals({ data }) {
//   const complete = data.allAirtable.nodes.filter(
//     signal => signal.data.Visibility === "Published"
//   )
//   const incomplete = data.allAirtable.nodes.filter(
//     signal => signal.data.Visibility !== "Published"
//   )

//   const title = "Signals Index"

//   return (
//     <Layout>
//       <SEO title={title} />
//       <Section>
//         <Content>
//           <h1>{title}</h1>
//           {complete.map(node => (
//             <li key={node.recordId}>
//               <Link to={`/signals/${slugFormat(node.data.Name)}`}>
//                 {node.data.Name}
//               </Link>
//             </li>
//           ))}
//         </Content>
//       </Section>
//     </Layout>
//   )
// }

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Signals" } }
      sort: { fields: data___Name, order: ASC }
    ) {
      nodes {
        recordId
        data {
          Name
          Visibility
        }
      }
    }
    markdown: allMarkdownRemark(
      filter: { frontmatter: { section: { in: ["descriptions"] } } }
    ) {
      nodes {
        html
        frontmatter {
          ref
          section
          title
        }
      }
    }
  }
`
